import './PostRequest.css';
import React from 'react';

class PostRequest extends React.Component {

  constructor(props) {
    super(props);

    this.dropDownChange = this.dropDownChange.bind(this);
    this.jasoDataAdd = this.jasoDataAdd.bind(this);
    this.jsonDataChange = this.jsonDataChange.bind(this);
    this.sendPut = this.sendPut.bind(this);

    this.state = {putType :  'json' , numberOfDatas : 1, UIjson : {0: ["", ""]}, returnString: ""};
  }

    render() {
      return (
        <div className = "PostRequest">
        <h4>Post Request:</h4>
        <label>Post Type:</label>
        <select className = "DropDown" onChange={this.dropDownChange} >
          <option value='json'>json</option>
        </select>
        <h5>json data:</h5>
        <button onClick ={this.jasoDataAdd} >Add Data</button>
        <table>
          {this.createTable(this.state.numberOfDatas)}
        </table>
        <button onClick = {this.sendPut}>send</button>
        <div className = "sepSmall"></div>
        <textarea
        className="UIConsole"
        readOnly
        defaultValue={this.state.returnString}
      />
      </div>
      );
    }

    sendPut(e)
    {
        var json ={};
        console.log("-------------------------");
        for(var i = 0; i < this.state.numberOfDatas; i++)
        {   
            var key = String(this.state.UIjson[i][0]);
            var value = this.state.UIjson[i][1];
            console.log(key + ":" + value);
            json[key] =value;
        }
        console.log(json);
        this.putRequest(this.props.baseURL,json);
    }

    dropDownChange(e)
    {
        this.setState({ putType: e.target.value });
    }

    urlChange(e)
    {
        this.setState({ requestURL: e.target.value });
    }

    jasoDataAdd(e)
    {
        var index = this.state.numberOfDatas ;
        this.setState({ numberOfDatas: index + 1 });
        this.state. UIjson[index] = ["" , ""];
    }

    jsonDataChange(e)
    {
        var name = String(e.target.className);
        console.log(name);
        var numb = name.substring(3);
        if(name.substring(0,3) == "key")
        {
            this.state.UIjson[numb][0] = e.target.value ;
        }
        else
        {
            this.state.UIjson[numb][1] = e.target.value ;
        }
        console.log(this.state.UIjson[numb]);
    }

    async putRequest(url, json)
    {
        let result = await makeRequest("POST", url, json);
        console.log(result);
        this.setState({returnString: result});
    }

    createTable = (lenght) => {
        let table = []
    
        // Outer loop to create parent
        for (let i = 0; i < lenght; i++) {
          let children = []
          
            children.push(
            <div className = "LineInList">
            <label>Key: </label>
            <input type = "Text" className = {"key" + i} onChange = {this.jsonDataChange}></input>
            <label>    Value:</label>
            <input type = "Text" className = {"val" + i} onChange = {this.jsonDataChange}></input>
            </div>

            );
          
          //Create the parent and add the children
          table.push(<tr key = {"line" + i}>{children}</tr>)
        }
        return table
      }
  
}
  export default PostRequest;

  function makeRequest(method, url, json) {
    console.log(url);
    var data = JSON.stringify(json);
  return new Promise(function (resolve, reject) {
      let xhr = new XMLHttpRequest();
      xhr.open(method, url);
      xhr.onload = function () {
          resolve(xhr.response);
      };
      xhr.send(data);
  });
}