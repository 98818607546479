import './App.css';
import SettingsPage from './SettingsPage.js';


function App() {
  return (
    <div className="App">
      <h1 className ="div1">Backend Debugger</h1>
      <h3 className = "div1">This website is used to debug web services.</h3>
      <SettingsPage></SettingsPage>
    </div>
  );
}

export default App;
