import './GetRequest.css';
import React from 'react';

class GetRequest extends React.Component {

    constructor(props) {
        super(props);
        this.state = {parameters: "", returnString: ""}
        this.urlChange = this.urlChange.bind(this);
        this.sendGet = this.sendGet.bind(this);
      }

    render() {
      return (
        <div className = "GetRequest">
          <h4>Get Request:</h4>
          <label>Request String:  </label>
          <label className = "codeSnipped">{this.props.baseURL} </label>
          <input type = "Text" onChange = {this.urlChange} value = {this.state.parameters}></input>
          <button onClick = {this.sendGet}>send</button>
          <div className = "sepSmall"></div>
          <textarea
          className="UIConsole"
          readOnly
          defaultValue={this.state.returnString}
        />
        </div>
      );
    }



    urlChange(e)
    {
        this.setState({ parameters: e.target.value });
    }

    sendGet(e)
    {   
        this.getRequest(this.props.baseURL + this.state.parameters);
    }

    async getRequest(url)
    {
        let result = await makeRequest("GET", url);
        console.log(result);
        this.setState({returnString: result});
    }
  }
  
  
  export default GetRequest;

  function makeRequest(method, url) {
      console.log(url);
    return new Promise(function (resolve, reject) {
        let xhr = new XMLHttpRequest();
        xhr.open(method, url);
        xhr.onload = function () {
            resolve(xhr.response);
        };
        xhr.send();
    });
}