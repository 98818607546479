import './SettingsPage.css';
import GetRequest from './GetRequest.js';
import PutRequest from './PutRequest.js';
import PostRequest from './PostRequest.js';
import React from 'react';

class SettingsPage extends React.Component {

    constructor(props) {
        super(props);

        this.dropDownChange = this.dropDownChange.bind(this);
        this.urlChange = this.urlChange.bind(this);

        this.state = { requestType : "GET", requestURL : "https://rothlin.com/TestApp/", requestParametes: <GetRequest baseURL = "https://rothlin.com/TestApp/"></GetRequest> };
      }

    render() {
      return (
        <div className = "Settings">
          <h3>Settings:</h3>
          <label>Request Mode: </label>
          <select className = "DropDown" onChange={this.dropDownChange} >
            <option value="GET">GET</option>
            <option value="PUT">PUT</option>
            <option value="POST">POST</option>
          </select>
          <div className = "sepSmall"></div>
          <label>Base URL: </label>
          <input type = "Text" onChange = {this.urlChange} className = "TextInput" value = {this.state.requestURL}></input>
          <hr className = "sep"></hr>
          <h3>Parameters:</h3>
          {this.state.requestParametes}
        </div>
      );
    }

    dropDownChange(e)
    {
        this.setState({ requestType: e.target.value});
        if(e.target.value == 'PUT')
        {
            this.setState({requestParametes : <PutRequest baseURL = {this.state.requestURL}></PutRequest>})
        }

        if(e.target.value == 'GET')
        {
            this.setState({requestParametes : <GetRequest baseURL = {this.state.requestURL} ></GetRequest>})
        }

        if(e.target.value == 'POST')
        {
            this.setState({requestParametes : <PostRequest></PostRequest>})
        }
        

    }

    urlChange(e)
    {
        this.setState({ requestURL: e.target.value });
        if(this.state.requestType == 'PUT')
        {
            this.setState({requestParametes : <PutRequest baseURL = {e.target.value}></PutRequest>})
        }

        if(this.state.requestType == 'GET')
        {
            this.setState({requestParametes : <GetRequest baseURL = {e.target.value}></GetRequest>})
        }

        if(this.state.requestType == 'POST')
        {
            this.setState({requestParametes : <PostRequest ></PostRequest>})
        }
    }
    
  }
  
  
  export default SettingsPage;